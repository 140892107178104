import styles from '@/styles/PageNotFound.module.css'
import Link from 'next/link'

const PageNotFound = () => {
  return (
    <section className={styles.container}>
      <h1 className={styles.title}>404 - Page Not Found</h1>
      <p className={styles.message}>
        The page you are looking for does not exist.
      </p>
      <Link className={`${styles.link} ${styles.button}`} href='/'>
        Go back to Home
      </Link>
    </section>
  )
}
export default PageNotFound
